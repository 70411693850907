// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        // 现有的翻译条目保持不变
        "ai_rate_services": "AI Rate Services",
        "account_records": "Account Records",
        "detection_rewrite_history": "Detection | Rewrite History",
        "recharge_spend_history": "Recharge | Spend History",
        "update_account": "Update Account",
        "recharge_credits": "Recharge Credits",
        "welcome_user": "Welcome, {{userName}}! Your current balance is {{balance}} credits.",
        "welcome_back_user": "Welcomeback, {{userName}}!",
        "not_logged_in": "Not Logged In, Click to Log In",
        "confirm_logout": "Confirm Logout",

        "logout_prompt": "Are you sure you want to log out?",
        "contact_support": "Contact Support",
        // 针对 Home.js 的新增翻译内容
        "welcome_to_nlphraser": "Welcome to NLPhraser",
        "nlphraser_description": "NLPhraser helps you detect AI-generated content and rewrite articles to reduce AI detection rates. Our advanced tools ensure your content is unique and more human-like.",
        "select_service": "Please select one of our services to get started:",
        "ai_detection": "AI Detection",
        "rewrite_content": "Rewrite Content",
        "about_nlphraser": "About NLPhraser",
        "about_nlphraser_text1": "NLPhraser is an advanced tool designed to help you detect and reduce AI-generated content in your articles. Whether you're a content creator, blogger, or student, our tool offers insights to ensure your content stands out.",
        "about_nlphraser_text2": "Our detection algorithm is highly accurate, making it easy to identify AI-generated text. Additionally, our rewriting tool helps you modify content to reduce AI detection rates, making your articles more authentic and engaging.",
        "about_nlphraser_text3": "Join us today and take the first step toward creating unique, high-quality content!",
        // Features.js - English Translations
        "ai_text_optimization_tool_title": "Ultra-Precise AI Text Optimization Tool",
        "ai_text_optimization_tool_description": "Our AI assistant helps you effortlessly manage AI-generated text. Below are some of our highlight features:",
        "precision_rewriting_title": "Precision Rewriting While Maintaining Original Structure",
        "precision_rewriting_text": "NLPhraser can precisely rewrite paragraphs with high AI detection rates without altering the overall structure of the article. This ensures the logical coherence of the text while effectively reducing AI detection rates, making the content more natural.",
        "long_text_rewriting_title": "Long-Text Rewriting, Supporting 3000+ Words",
        "long_text_rewriting_text": "NLPhraser’s rewriting tool can handle long texts efficiently, processing articles over 3000 words. By deeply analyzing language structure, NLPhraser minimizes AI detection rates beyond the limits of other tools, ensuring more natural and original content.",
        "turnitin_accuracy_title": "Accuracy Aligned with Turnitin Detection Standards",
        "turnitin_accuracy_text": "NLPhraser’s rewriting function is specifically designed to handle professional AI detection tools like Turnitin. Through precise text rewriting, it ensures that the article passes Turnitin’s detection, reducing the AI rate to a negligible level and helping users maintain the originality of their content.",
        "exceptional_performance_title": "Exceptional Rewriting Performance",
        "exceptional_performance_text": "NLPhraser can deeply rewrite texts to make them more human-like, minimizing detection by various AI tools. Through intelligent analysis and processing, NLPhraser ensures that every article meets high standards of fluency while maintaining its uniqueness.",
        "data_security_privacy_title": "Data Security and Privacy Protection",
        "data_security_privacy_description": "We use the most advanced encryption technology, providing higher security than traditional methods and far surpassing international security standards. NLPhraser ensures that user data cannot be cracked or leaked during transmission and processing through highly complex encryption techniques. Additionally, we strictly follow privacy protection protocols, automatically deleting all uploaded content after processing, offering truly “no-trace” data handling to fully protect your privacy.",
        "data_security_title": "Advanced Data Security Protection",
        "data_security_text": "We use cutting-edge encryption technology to not only ensure the security of your data transmission but also to fully respect your privacy. NLPhraser adheres to the principle of \"forgetting is protection,\" meaning that any content uploaded by users is automatically deleted after processing. We do not store or remember any user data. Whether it’s your documents, information, or interactions, everything is deleted after completion, ensuring a truly “no-trace” experience that keeps your privacy safe.",
        "customer_support_title": "Outstanding Customer Support",
        "customer_support_text": "Our dedicated customer service team is always ready to provide you with the best support with professionalism, efficiency, and a friendly attitude. Whatever issues you encounter, we will assist you promptly, ensuring a seamless user experience.",
        "technological_expertise_title": "Leading Technological Expertise",
        "technological_expertise_text": "NLPhraser combines cutting-edge natural language processing technology with years of industry experience to create a top-tier text processing tool. Whether detecting or optimizing AI-generated content, our technology remains at the forefront globally.",
        "global_standard_title": "Professional-Grade Global Standard Performance",
        "global_standard_text": "We collaborate with the world’s top research institutions to develop the industry’s most accurate plagiarism detection engine, capable of fully analyzing, detecting, and optimizing every AI-generated article to ensure it meets the highest quality standards.",
        // ContactSupport.js 新增翻译内容
        "faq_title": "Frequently Asked Questions",
        "faq_question1": "How to ensure content cannot be detected by AI detectors?",
        "faq_answer1": "NLPhraser uses advanced algorithms to modify AI-generated content, making it undetectable by multiple AI detectors such as Originality AI, Winston AI, GPTZero, ZeroGPT, and Turnitin.",
        "faq_question2": "Are there any discounts for annual plans?",
        "faq_answer2": "Yes! If you pay annually, you can save up to 60% compared to monthly payments.",
        "faq_question3": "Why not use regular rewriting tools?",
        "faq_answer3": "Regular rewriting tools cannot effectively bypass AI detectors, especially Turnitin. We use custom algorithms to modify AI-generated content, making it undetectable by multiple AI detectors.",
        "faq_question4": "Can it bypass Turnitin AI detection?",
        "faq_answer4": "Yes! NLPhraser has been consistently helping users bypass Turnitin AI detection, which has been verified by third-party institutions with Turnitin instructor accounts.",
        "faq_question5": "Will the quality of the content be affected?",
        "faq_answer5": "No, NLPhraser's AI is trained on high-quality content, and the generated content is similar in quality to the original. In some cases, NLPhraser's AI can even enhance content quality based on the writing style you choose.",
        "faq_question6": "Will my content be detected as plagiarism?",
        "faq_answer6": "Absolutely not! Our AI strictly generates 100% original and unique content. Every time you use the tool, you can expect a different result, ensuring your text is free from plagiarism.",
        "contact_us": "Contact Us",
        "contact_us_text": "If you have any questions or need further assistance, please send an email to:",
        "copy_email": "Copy Email Address",
        "back_home": "Back to Home",
        "email_copied": "Email address copied to clipboard!",
        // CreditHistory.js 新增翻译内容
        "credit_history": "Credit History",
        "spend_history": "Spend History",
        "recharge_history": "Recharge History",
        "spend_id": "Spend ID",
        "recharge_id": "Recharge ID",
        "type": "Type",
        "spend": "Spend",
        "amount": "Amount",
        "points": "Points",
        "recharge_credit": "Recharge Credit",
        "loading": "Loading...",
        "login_required": "Login Required",
        "login_prompt": "You need to log in to access this page. Would you like to log in now?",
        "no_home": "Back to home",
        "yes_login": "Login",
        // DetectionResult.js 新增翻译内容
        "detection_result": "AIGC Detection Result",
        "humanize": "Humanize",
        "progress_humanize": "Rewriting the article...",
        "progress_calculating_ai": "Calculating the AI rate for the new text...",
        "original_text": "Original Text",
        "humanized_text": "Humanized Text",
        "likely_written_by": "Your text is likely written by",
        "likely_ai_generated": "sentences are likely AI generated",
        "humanized_ai_rate": "Your humanized text has an AI rate of:",
        "ai": "AI",
        "human": "Human",
        "error_chart": "Error creating chart:",
        "error_humanize": "Error during humanization:",
        // History.js 新增翻译内容
        "history_title": "Submission History",
        "history_type": "Select history type",
        "check_history": "Check History",
        "rewrite_history": "Rewrite History",
        "check_id": "Check ID",
        "label": "Label",
        "ai_rate": "AI Rate",
        "time": "Time",
        "rewrite_id": "Rewrite ID",
        "mode": "Mode",
        "rewritten_text": "Rewritten Text",
        "login_prompt_history": "You need to log in to view your submission history.",
        "fetch_error": "Error fetching history data",
        // Login.js 新增翻译内容
        "login": "Login",
        "fill_all_fields": "Please fill out all fields.",
        "login_response": "Login response",
        "user_info": "User information",
        "balance_info": "Balance information",
        "login_success": "Login successful!",
        "login_failed": "Login failed, please try again.",
        "login_error": "Email or password is incorrect, please try again.",
        "login_error_console": "Error during login:",
        "try_later": "An error occurred, please try again later.",
        "email": "Email",
        "password": "Password",
        "new_user": "New user",
        "click_here_register": "Click here to register",
        "forgot_password": "Forgot password",
        "click_here_change": "Click here to change",
        // Recharge.js 新增翻译内容
        "recharge": "Recharge",
        "recharge_description": "NLPhraser credits can be used for the services you need. You can pay in RMB or USD. No refunds after recharge, thank you for your understanding.",
        "credits": "credits",
        "select_package": "Select Package",
        "basic_package": "Basic Package",
        "standard_package": "Standard Package",
        "premium_package": "Premium Package",
        "diamond_package": "Diamond Package",
        "enterprise_package": "Enterprise Package",
        "credit_card": "Credit Card",
        "paypal": "PayPal",
        "apple_pay": "Apple Pay",
        "google_pay": "Google Pay",
        "alipay": "Alipay",
        "wechat_pay": "WeChat Pay",
        "login_prompt_recharge": "You need to log in to access the recharge page.",
        "payment_success": "Payment Successful",
        "payment_failed": "Payment Failed",
        "payment_intent": "Payment Intent",
        "payment_intent_client_secret": "Payment Intent Client Secret",
        "payment_params": "All payment parameters",
        "payment_result_sent": "Payment result sent to backend:",
        "payment_result_error": "Error sending payment result to backend:",
        // Register.js 新增翻译内容
        "register": "Register",
        "username": "Username",
        "confirm_password": "Confirm Password",
        "password_mismatch": "Passwords do not match. Please confirm your password.",
        "username_not_digits": "Username cannot be only digits.",
        "invalid_code": "Invalid verification code.",
        "register_response": "Register response",
        "register_success": "Registration successful! Please log in.",
        "register_failed": "Registration failed. Please try again.",
        "register_error": "Error registering user:",
        "email_verification": "Email Verification",
        "enter_verification_code": "Enter Verification Code",
        "verification_code_sent": "Verification code has been sent.",
        "verification_code_failed": "Failed to send verification code.",
        "emailjs_response": "EmailJS response",
        "emailjs_error": "EmailJS error",
        "code_sent_error": "Error:",
        "verification_code_sent_status": "Verification code sent status:",
        "sending_verification_code": "Sending verification code",
        "close": "Close",
        "verify": "Verify",
        // RewriteResult.js 新增翻译内容
        "rewrite_result": "Rewrite Result",
        "rewrite_mode": "Rewrite Mode",
        "no_rewritten_text": "No rewritten text available",
        "error": "Error",
        "id": "ID",
        "back": "Back",
        // ChangePassword.js 新增翻译内容
        "change_password": "Change Password",
        "invalid_email": "Please enter a valid email address.",
        "send_verification_code": "Send Verification Code",
        "resend_code": "Resend Code",
        "verification_failed": "Failed to send verification code.",
        "new_password": "New Password",
        "password_changed": "Password changed successfully!",
        "email_not_registered": "Email not registered. Please use a registered email.",
        "password_change_failed": "Failed to change password.",
        "verification_prompt": "Please enter the verification code sent to your email.",
        "cancel": "Cancel",
        // CheckoutForm.js 新增翻译内容
        "submit_payment": "Submit Payment",
        // PaymentModal.js 新增翻译内容
        "order_summary": "Order Summary",
        "subtotal": "Subtotal",
        "total": "Total",
        "proceed_to_payment": "Proceed to Payment",
        "payment": "Payment",
        "payment_intent_error": "Error creating payment intent",
        // TextProcessing.js 新增翻译内容
        "aigc_detection": "AIGC Detection",
        "rewrite": "Rewrite",
        "english_only_warning": "English only. This action cannot be undone! Do not input text in other languages! Your information is private and will not be shared. Text input method only.",
        "aigc_usage_detection": "AIGC Usage Detection",
        "service_settings": "Service Settings",
        "service": "Service",
        "method": "Method",
        "upload_file": "Upload File",
        "enter_text": "Enter Text",
        "file_upload_info": "Max file size: 5MB. Supports PDF and Word files only. PDF must not be image-based.",
        "enter_text_placeholder": "Enter text here...",
        "select_mode": "Select Mode",
        "easy": "Easy",
        "medium": "Medium",
        "aggressive": "Aggressive",
        "detect": "Detect",
        "credits_usage_info": "Each word costs 0.1 credit.",
        "processing_request": "Processing your request...",
        "confirm_credits_usage": "Confirm Credits Usage",
        "credits_confirmation": "This action will cost {{credits}} credits for {{wordCount}} words. Do you wish to proceed?",
        "confirm": "Confirm",
        "no_rewrite_text": "No rewritten text available.",
        "no_api_data": "No data returned from API.",
        "insufficient_credits": "Insufficient credits",
        "insufficient_credits_message": "Insufficient credits. Please recharge.",
        "request_error": "An error occurred while processing the request.",
        "select_service_method": "Please select a service and method.",
        "select_rewrite_mode": "Please select a rewrite mode.",
        // Update_User.js 新增翻译内容
        "update_user_info": "Update User Information",
        "update": "Update",
        "delete_account": "Delete Account",
        "confirm_delete_account": "Confirm Delete Account",
        "enter_email_to_confirm": "Enter your email to confirm",
        "login_required_message": "You need to log in to access this page.",

        "username_update_success": "Username updated successfully!",
        "username_update_failed": "Failed to update username.",
        "email_mismatch": "Email does not match.",
        "account_delete_success": "Account deleted successfully!",
        "account_delete_failed": "Failed to delete account.",
        // GifPlayer.js 新增翻译内容
        "ai_detection": "AI Detection",
        "fine_grained_rewrite": "Fine-Grained Rewrite",
        "long_text_rewrite": "Long Text Rewrite",
        "ai_detection_description": "This feature can rival leading plagiarism detection tools like Turnitin. It accurately identifies AI-generated content, ensuring that your work is original and free from AI-generated plagiarism. Ideal for academic institutions and content creators who want to maintain authenticity and originality.",
        "fine_grained_rewrite_description": "This tool excels at rewriting text while maintaining the intricate structure of paragraphs and articles. Unlike other tools that often distort the logical flow or coherence, this feature ensures your content remains intact, preserving the nuances and details, all while improving readability and quality.",
        "long_text_rewrite_description": "Supporting documents with over 4000 words, this tool is perfect for handling extensive texts such as research papers, reports, and long-form articles. It ensures that even large-scale documents are rewritten effectively, while maintaining the key ideas and flow of the original content.",
        // PaymentResult.js 新增翻译内容
        "payment_result": "Payment Result",
        "loading": "Loading...",
        "confirming_order": "Confirming your order",
        "payment_success": "Payment Successful",
        "payment_failed": "Payment failed, please try again.",
        "payment_amount": "Payment Amount",
        "payment_time": "Payment Time",
        "continue_recharge": "Continue Recharge",
        "start_using": "Start Using",
        "try_again": "Try Again",
        "unknown_error": "Unknown Error",
        "missing_payment_intent": "Missing payment intent",
      }
    },
    zh: {
      translation: {
        // 现有的翻译条目保持不变
        "welcome": "欢迎来到我们的网站",
        "ai_rate_services": "AI率服务",
        "account_records": "账户记录",
        "detection_rewrite_history": "检测 | 重写历史",
        "recharge_spend_history": "充值 | 消费历史",
        "update_account": "更新账户",
        "recharge_credits": "充值积分",
        "welcome_user": "欢迎, {{userName}}! 你的当前余额是 {{balance}} 积分。",
        "not_logged_in": "未登录，点击登录",
        "confirm_logout": "确认退出登录",
        "logout_prompt": "你确定要退出登录吗？",
        "contact_support": "联系客服",
        // 针对 Home.js 的新增翻译内容
        "welcome_to_nlphraser": "欢迎来到 NLPhraser",
        "nlphraser_description": "NLPhraser 帮助您检测 AI 生成的内容，并重写文章以降低 AI 检测率。我们的高级工具确保您的内容独特且更具人性化。",
        "select_service": "请选择我们的服务开始使用：",
        "ai_detection": "AI检测",
        "rewrite_content": "重写内容",
        "about_nlphraser": "关于 NLPhraser",
        "about_nlphraser_text1": "NLPhraser 是一个先进的工具，旨在帮助您检测并减少文章中的 AI 生成内容。无论您是内容创作者、博主还是学生，我们的工具都能提供洞察，确保您的内容脱颖而出。",
        "about_nlphraser_text2": "我们的检测算法高度准确，使得识别 AI 生成的文本变得容易。此外，我们的重写工具帮助您修改内容，以减少 AI 检测率，使您的文章更加真实和吸引人。",
        "about_nlphraser_text3": "今天就加入我们，迈出创建独特高质量内容的第一步！",
        // Features.js - 中文翻译
        "ai_text_optimization_tool_title": "超精准AI文本优化工具",
        "ai_text_optimization_tool_description": "我们的AI助手帮助您轻松管理AI生成的文本。以下是我们的一些亮点功能：",
        "precision_rewriting_title": "精准重写保持原始结构",
        "precision_rewriting_text": "NLPhraser可以精确地重写高AI检测率的段落，而不改变文章的整体结构。这确保了文本的逻辑连贯性，同时有效降低AI检测率，使内容更加自然。",
        "long_text_rewriting_title": "长文本重写，支持3000+字",
        "long_text_rewriting_text": "NLPhraser的重写工具能够高效处理长文本，处理超过3000字的文章。通过深度分析语言结构，NLPhraser超越了其他工具的极限，确保内容更加自然和原创。",
        "turnitin_accuracy_title": "与Turnitin检测标准一致的准确性",
        "turnitin_accuracy_text": "NLPhraser的重写功能专为处理专业AI检测工具如Turnitin而设计。通过精确的文本重写，确保文章通过Turnitin的检测，将AI率降至可以忽略的水平，帮助用户保持内容的原创性。",
        "exceptional_performance_title": "卓越的重写表现",
        "exceptional_performance_text": "NLPhraser可以深入重写文本，使其更具人性化，最小化各种AI工具的检测。通过智能分析和处理，NLPhraser确保每篇文章都达到高标准的流利度，同时保持其独特性。",
        "data_security_privacy_title": "数据安全和隐私保护",
        "data_security_privacy_description": "我们使用最先进的加密技术，提供比传统方法更高的安全性，远超国际安全标准。NLPhraser确保用户数据在传输和处理过程中不会被破解或泄露，通过高度复杂的加密技术。此外，我们严格遵循隐私保护协议，处理完成后自动删除所有上传内容，提供真正的“无痕”数据处理，全面保护您的隐私。",
        "data_security_title": "高级数据安全保护",
        "data_security_text": "我们使用尖端的加密技术，不仅确保您的数据传输安全，还完全尊重您的隐私。NLPhraser遵循“遗忘即保护”的原则，用户上传的任何内容在处理后会自动删除。我们不存储或记住任何用户数据。无论是您的文档、信息还是互动，一切在完成后都会被删除，确保真正的“无痕”体验，保护您的隐私安全。",
        "customer_support_title": "卓越的客户支持",
        "customer_support_text": "我们的专属客户服务团队随时准备为您提供最好的支持，具备专业、高效和友好的态度。无论您遇到什么问题，我们都会及时为您提供帮助，确保无缝的用户体验。",
        "technological_expertise_title": "领先的技术专长",
        "technological_expertise_text": "NLPhraser结合尖端的自然语言处理技术和多年的行业经验，打造一流的文本处理工具。无论是检测还是优化AI生成的内容，我们的技术始终处于全球前沿。",
        "global_standard_title": "专业级全球标准表现",
        "global_standard_text": "我们与世界顶尖的研究机构合作，开发出业内最准确的抄袭检测引擎，能够全面分析、检测和优化每一篇AI生成的文章，以确保其达到最高质量标准。",
        // ContactSupport.js 新增翻译内容
        "faq_title": "常见问题",
        "faq_question1": "如何确保内容不会被 AI 检测器检测到？",
        "faq_answer1": "NLPhraser 采用先进的算法来修改 AI 生成的内容，使其无法被多种 AI 检测器（如 Originality AI, Winston AI, GPTZero, ZeroGPT 和 Turnitin）检测到。",
        "faq_question2": "年度计划有折扣吗？",
        "faq_answer2": "有！如果您预付一年的费用，相比按月支付可节省多达60%。",
        "faq_question3": "为什么不使用常规的改写工具？",
        "faq_answer3": "常规的改写工具无法有效地绕过 AI 检测器（尤其是 Turnitin）。我们使用自定义算法来修改 AI 生成的内容，使其无法被多种 AI 检测器检测到。",
        "faq_question4": "是否能绕过 Turnitin AI 检测？",
        "faq_answer4": "是的！NLPhraser 已经持续帮助用户绕过 Turnitin AI 检测。这也得到了拥有 Turnitin 指导员账户的第三方机构的验证。",
        "faq_question5": "内容的质量会受到影响吗？",
        "faq_answer5": "不会，NLPhraser 的 AI 经过高质量内容的训练，生成的内容质量与原始内容相似。在某些情况下，NLPhraser 的 AI 甚至可以根据您选择的写作风格提高内容质量。",
        "faq_question6": "我的内容会被检测到剽窃吗？",
        "faq_answer6": "绝对不会！我们的 AI 严格生成100%原创且独特的内容。每次使用工具时，您都可以期望得到不同的结果，使您的文本避免剽窃。",
        "contact_us": "联系我们",
        "contact_us_text": "如果您有任何问题或需要进一步的帮助，请发送邮件至：",
        "copy_email": "复制邮箱地址",
        "email_copied": "邮箱地址已复制到剪切板！",
        // CreditHistory.js 新增翻译内容
        "credit_history": "积分记录",
        "spend_history": "积分消费记录",
        "recharge_history": "积分充值记录",
        "spend_id": "消费ID",

        "recharge_id": "充值ID",
        "type": "类型",
        "time": "时间",
        "spend": "花费",
        "amount": "金额",
        "points": "积分",
        "recharge_credit": "充值积分",
        "login_required": "需要登录",
        "login_prompt": "您需要登录才能访问此页面。现在要登录吗？",
        "no_home": "返回首页",
        "yes_login": "登录",
        "fetch_error": "获取历史数据时出错",
        // DetectionResult.js 新增翻译内容
        "detection_result": "AIGC 检测结果",
        "humanize": "重写",
        "progress_humanize": "正在对文章进行重写...",
        "progress_calculating_ai": "正在计算新文章的 AI 率...",
        "original_text": "原始文本",
        "humanized_text": "重写文本",
        "likely_written_by": "您的文本可能由",
        "likely_ai_generated": "句子可能是 AI 生成的",
        "humanized_ai_rate": "您的重写文本的 AI 率为：",
        "ai": "AI",
        "human": "人类",
        "error_chart": "创建图表时出错：",
        "error_humanize": "重写过程中出错：",
        // History.js 新增翻译内容
        "history_title": "历史提交",
        "history_type": "选择历史类型",
        "check_history": "检测历史",
        "rewrite_history": "重写历史",
        "check_id": "检测ID",
        "label": "标签",
        "ai_rate": "AI率",
        "rewrite_id": "重写ID",
        "mode": "模式",
        "login_prompt_history": "您需要先登录才能查看历史提交。",
        // Login.js 新增翻译内容
        "login_response": "登录响应",
        "user_info": "用户信息",
        "balance_info": "余额信息",
        "login_success": "登录成功！",
        "login_failed": "登录失败，请重试。",
        "login_error": "邮箱或密码错误，请重试。",
        "login_error_console": "登录时出错：",
        "try_later": "发生错误，请稍后再试。",
        "email": "邮箱",
        "password": "密码",
        "new_user": "新用户",
        "click_here_register": "点击这里注册",
        "forgot_password": "忘记密码",
        "click_here_change": "点击这里修改",
        // Recharge.js 新增翻译内容
        "recharge": "充值",
        "recharge_description": "NLPhraser 积分可用于您所需的服务。您可以使用人民币或美元支付。充值后不支持退款，感谢您的理解。",
        "credits": "积分",
        "select_package": "选择套餐",
        "basic_package": "基础套餐",
        "standard_package": "标准套餐",
        "premium_package": "高级套餐",
        "diamond_package": "钻石套餐",
        "enterprise_package": "企业套餐",
        "credit_card": "信用卡",
        "paypal": "PayPal",
        "apple_pay": "Apple Pay",
        "google_pay": "Google Pay",
        "alipay": "支付宝",
        "wechat_pay": "微信支付",
        "login_prompt_recharge": "您需要登录才能访问充值页面。",
        "login": "登录",
        "payment_success": "支付成功",
        "payment_failed": "支付失败",
        "payment_intent": "支付意图",
        "payment_intent_client_secret": "支付意图客户端密钥",
        "payment_params": "所有支付参数",
        "payment_result_sent": "支付结果已发送到后台:",
        "payment_result_error": "将支付结果发送到后台时出错:",
        "close": "关闭",
        // Register.js 新增翻译内容
        "register": "注册",
        "username": "用户名",
        "confirm_password": "确认密码",
        "invalid_email": "请输入有效的邮箱地址。",
        "password_mismatch": "密码不匹配。请确认您的密码。",
        "fill_all_fields": "请填写所有字段。",
        "username_not_digits": "用户名不能仅为数字。",
        "invalid_code": "验证码无效。",
        "register_response": "注册响应",
        "register_success": "注册成功！请登录。",
        "register_failed": "注册失败。请重试。",
        "register_error": "注册用户时发生错误：",
        "email_verification": "邮箱验证",
        "enter_verification_code": "输入验证码",
        "verification_code_sent": "验证码已发送。",
        "verification_code_failed": "验证码发送失败。",
        "emailjs_response": "EmailJS 响应",
        "emailjs_error": "EmailJS 错误",
        "code_sent_error": "错误：",
        "verification_code_sent_status": "验证码发送状态：",
        "sending_verification_code": "发送验证码",
        "verify": "验证",
        "resend_code": "重新发送验证码",
        // RewriteResult.js 新增翻译内容
        "rewrite_result": "重写结果",
        "rewritten_text": "重写文本",
        "rewrite_mode": "重写模式",
        "no_rewritten_text": "暂无重写文本",
        "id": "ID",
        "back": "返回",
        // ChangePassword.js 新增翻译内容
        "change_password": "更改密码",
        "send_verification_code": "发送验证码",
        "verification_failed": "验证码发送失败。",
        "new_password": "新密码",
        "password_changed": "密码更改成功！",
        "email_not_registered": "邮箱未注册。请使用已注册的邮箱。",
        "password_change_failed": "密码更改失败。",
        "verification_prompt": "请输入发送到您邮箱的验证码。",
        // CheckoutForm.js 新增翻译内容
        "submit_payment": "提交支付",
         // PaymentModal.js 新增翻译内容
         "order_summary": "订单摘要",
         "subtotal": "小计",
         "total": "总计",
         "proceed_to_payment": "继续支付",
         "payment": "支付",
         "cancel": "取消",
         "payment_intent_error": "创建支付意图时出错",
         // TextProcessing.js 新增翻译内容
        "aigc_detection": "AIGC 检测",
        "rewrite": "重写",
        "english_only_warning": "仅支持英文，此操作无法撤销！请勿输入其他语言的文本！您的信息是私密的，不会被共享。仅支持文本输入。",
        "aigc_usage_detection": "AIGC 使用率检测",
        "service_settings": "服务设置",
        "service": "服务",
        "method": "方法",
        "upload_file": "上传文件",
        "enter_text": "输入文本",
        "file_upload_info": "最大文件大小：5MB，仅支持上传 PDF 和 Word。PDF 文件不能为图片格式。",
        "enter_text_placeholder": "在此处输入文本...",
        "select_mode": "选择模式",
        "easy": "简单",
        "medium": "中等",
        "aggressive": "激进",
        "detect": "检测",
        "credits_usage_info": "每个单词消耗 0.1 积分。",
        "loading": "加载中...",
        "processing_request": "正在处理您的请求...",
        "confirm_credits_usage": "确认积分消耗",
        "credits_confirmation": "此操作将消耗 {{credits}} 积分，共计 {{wordCount}} 个单词。您确定要继续吗？",
        "confirm": "确认",
        "no_rewrite_text": "未能提供重写文本。",
        "no_api_data": "API 没有返回数据。",
        "error": "错误",
        "insufficient_credits": "余额不足",
        "insufficient_credits_message": "余额不足，请充值。",
        "request_error": "处理请求时发生错误。",
        "select_service_method": "请选择服务和方法。",
        "select_rewrite_mode": "请选择重写模式。",
         // Update_User.js 新增翻译内容
        "update_user_info": "更新用户信息",
        "update": "更新",
        "delete_account": "删除账户",
        "confirm_delete_account": "确认删除账户",
        "enter_email_to_confirm": "请输入您的邮箱以确认",

        "login_required_message": "您需要登录才能访问此页面。",
        "back_home": "返回首页",
        "username_update_success": "用户名更新成功！",
        "username_update_failed": "更新用户名失败。",
        "email_mismatch": "邮箱不匹配。",
        "account_delete_success": "账户删除成功！",
        "account_delete_failed": "删除账户失败。",
        // GifPlayer.js 新增翻译内容
        "ai_detection": "AI 检测",
        "fine_grained_rewrite": "精细化重写",
        "long_text_rewrite": "长文本重写",
        "ai_detection_description": "此功能可以与领先的抄袭检测工具（如 Turnitin）媲美。它能够准确识别 AI 生成的内容，确保您的作品是原创的，且没有 AI 生成的抄袭。非常适合学术机构和希望保持原创性和真实性的内容创作者。",
        "fine_grained_rewrite_description": "该工具在保持段落和文章结构的同时，擅长重写文本。与其他工具不同，它确保内容保持完整，同时提高可读性和质量，不会破坏逻辑流畅性或连贯性。",
        "long_text_rewrite_description": "支持超过 4000 字的文档，该工具非常适合处理大型文本，例如研究论文、报告和长篇文章。它确保即使是大规模文档也能有效重写，同时保持原始内容的主要思想和逻辑流畅性。",
        // PaymentResult.js 新增翻译内容
        "payment_result": "支付结果",
        "loading": "加载中...",
        "confirming_order": "正在确认订单信息",
        "payment_success": "支付成功",
        "payment_failed": "支付失败，请再次尝试。",
        "payment_amount": "支付金额",
        "payment_time": "支付时间",
        "continue_recharge": "继续充值",
        "start_using": "开始使用",
        "try_again": "再次尝试",
        "unknown_error": "未知错误",
        "missing_payment_intent": "缺少支付意图",

      }
    },
  },
  lng: "en", // 默认语言
  fallbackLng: "en", // 回退语言
  interpolation: {
    escapeValue: false, // React 已经处理了转义
  },
});

export default i18n;
