import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Button, Modal, Spinner, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'; // 引入 useTranslation

const PaymentResult = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [paymentData, setPaymentData] = useState(null);
  const [showLoading, setShowLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t } = useTranslation(); // 使用 useTranslation

  useEffect(() => {
    const fetchPaymentResult = async () => {
      const searchParams = new URLSearchParams(location.search);
      const paymentIntent = searchParams.get('payment_intent');

      if (!paymentIntent) {
        setError(t('missing_payment_intent'));
        setShowLoading(false);
        setLoading(false);
        return;
      }

      const requestData = { payment_result_id: paymentIntent };
      console.log('Request Data:', requestData);

      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/payment_result`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(requestData),
        });

        console.log('Response Status:', response.status);

        if (!response.ok) {
          console.error('Failed to fetch payment result:', response.statusText);
          setError(t('payment_failed'));
          return;
        }

        const data = await response.json();
        console.log('Response Data:', data);

        if (data.status === 'succeeded') {
          setPaymentData(data);

          // 查询 sessionStorage 中的 referral 值
          const referral = sessionStorage.getItem('referral');
          const credits = sessionStorage.getItem('credits');
          const promoCode = sessionStorage.getItem('promoCode');
          const userId = sessionStorage.getItem('userId');

          // 如果 referral 为 True，打印存储的 credit、promoCode 和 userId
          if (referral === 'True') {
            console.log('Referral:', referral);
            console.log('Credits (not final):', credits);
            console.log('Promo Code:', promoCode);
            console.log('User ID:', userId);
          }
        } else {
          setError(t('payment_failed'));
        }
      } catch (error) {
        console.error('Error fetching payment result:', error);
        setError(t('payment_failed'));
      } finally {
        setShowLoading(false);
        setLoading(false);
      }
    };

    fetchPaymentResult();
  }, [location, t]);

  return (
    <Container className="p-5 rounded shadow-lg" style={{ marginTop: '60px', backgroundColor: '#fff', fontFamily: 'Arial, sans-serif', textAlign: 'center' }}>
      <h1 className="mb-4" style={{ fontWeight: 'bold', fontSize: '2rem', color: '#333' }}>{t('payment_result')}</h1>
      {loading ? (
        <Modal show={showLoading} centered>
          <Modal.Body className="text-center">
            <Spinner animation="border" role="status" style={{ color: '#4A90E2' }}>
              <span className="visually-hidden">{t('loading')}</span>
            </Spinner>
            <p className="mt-3">{t('confirming_order')}</p>
          </Modal.Body>
        </Modal>
      ) : error ? (
        <Alert variant="light" className="p-4">
          <i className="fas fa-times-circle" style={{ fontSize: '3rem', color: '#E74C3C' }}></i>
          <p className="mt-3" style={{ fontSize: '1.2rem', color: '#333' }}>{error}</p>
          <Button 
            variant="dark" 
            className="m-2" 
            style={{ fontSize: '1rem', padding: '10px 20px' }} 
            onClick={() => navigate('/recharge')}
          >
            {t('try_again')}
          </Button>
        </Alert>
      ) : paymentData ? (
        <div className="text-center">
          <i className="fas fa-check-circle" style={{ fontSize: '3rem', color: '#2ECC71' }}></i>
          <p className="mt-3" style={{ fontSize: '1.5rem', color: '#2ECC71' }}>{t('payment_success')}</p>
          <p style={{ fontSize: '1.2rem', color: '#333' }}>{t('payment_amount')}: <span style={{ fontWeight: 'bold' }}>{paymentData.amount} {paymentData.currency.toUpperCase()}</span></p>
          <p style={{ fontSize: '1.2rem', color: '#333' }}>{t('payment_time')}: <span style={{ fontWeight: 'bold' }}>{paymentData.created}</span></p>
          <Button 
            variant="dark" 
            className="m-2" 
            style={{ fontSize: '1rem', padding: '10px 20px' }} 
            onClick={() => navigate('/recharge')}
          >
            {t('continue_recharge')}
          </Button>
          <Button 
            variant="dark" 
            className="m-2" 
            style={{ fontSize: '1rem', padding: '10px 20px' }} 
            onClick={() => navigate('/AI_detector')}
          >
            {t('start_using')}
          </Button>
        </div>
      ) : (
        <p className="text-center">{t('unknown_error')}</p>
      )}
    </Container>
  );
};

export default PaymentResult;
