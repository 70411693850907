// components/static/Features.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const primaryFeatures = [
  {
    title: 'precision_rewriting_title',
    text: 'precision_rewriting_text',
    icon: '🖋️', // Writing/Editing icon
    bgColor: '#e0f7fa'
  },
  {
    title: 'long_text_rewriting_title',
    text: 'long_text_rewriting_text',
    icon: '📄', // Document icon
    bgColor: '#f1f8e9'
  },
  {
    title: 'turnitin_accuracy_title',
    text: 'turnitin_accuracy_text',
    icon: '🎯', // Target icon
    bgColor: '#fff3e0'
  },
  {
    title: 'exceptional_performance_title',
    text: 'exceptional_performance_text',
    icon: '🌟', // Star icon
    bgColor: '#fce4ec'
  }
];

const secondaryFeatures = [
  {
    title: 'data_security_title',
    text: 'data_security_text',
    icon: '🔐', // Data Security icon
    bgColor: '#ffebee'
  },
  {
    title: 'customer_support_title',
    text: 'customer_support_text',
    icon: '💬', // Customer Support icon
    bgColor: '#fffde7'
  },
  {
    title: 'technological_expertise_title',
    text: 'technological_expertise_text',
    icon: '💡', // Innovation icon
    bgColor: '#e3f2fd'
  },
  {
    title: 'global_standard_title',
    text: 'global_standard_text',
    icon: '🔍', // Professional Performance icon
    bgColor: '#f9fbe7'
  }
];

const Features = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="container mt-5 p-5 bg-white rounded">
        <div className="text-center mb-4">
          <h2>{t('ai_text_optimization_tool_title')}</h2>
          <p>{t('ai_text_optimization_tool_description')}</p>
        </div>
        <div className="row">
          {primaryFeatures.map((feature, index) => (
            <div className="col-md-6 mb-4" key={index}>
              <div className="card h-100" style={{ backgroundColor: feature.bgColor }}>
                <div className="card-body text-center">
                  <div className="feature-icon mb-3" style={{ fontSize: '2rem' }}>{feature.icon}</div>
                  <h5 className="card-title">{t(feature.title)}</h5>
                  <p className="card-text">{t(feature.text)}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="container mt-5 p-5 bg-white rounded">
        <div className="text-center mb-4">
          <h2>{t('data_security_privacy_title')}</h2>
          <p>{t('data_security_privacy_description')}</p>
        </div>
        <div className="row">
          {secondaryFeatures.map((feature, index) => (
            <div className="col-md-6 mb-4" key={index}>
              <div className="card h-100" style={{ backgroundColor: feature.bgColor }}>
                <div className="card-body text-center">
                  <div className="feature-icon mb-3" style={{ fontSize: '2rem' }}>{feature.icon}</div>
                  <h5 className="card-title">{t(feature.title)}</h5>
                  <p className="card-text">{t(feature.text)}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Features;
