import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; // 引入 useTranslation

const GifPlayer = () => {
    const { t } = useTranslation(); // 使用 useTranslation
    const [hoverIndex, setHoverIndex] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(1); // 默认选择精细化重写

    const gifs = [
        '/images/AigcDetection.gif',
        '/images/FineGrainedRewrites.gif',
        '/images/LongTextRewrite.gif'
    ];

    const titles = [
        t('ai_detection'),
        t('fine_grained_rewrite'),
        t('long_text_rewrite')
    ];

    const descriptions = [
        t('ai_detection_description'),
        t('fine_grained_rewrite_description'),
        t('long_text_rewrite_description')
    ];

    const handleMouseEnter = (index) => setHoverIndex(index);
    const handleMouseLeave = () => setSelectedIndex(hoverIndex !== null ? hoverIndex : selectedIndex);
    const handleMouseClick = (index) => setSelectedIndex(index);

    const currentGifIndex = hoverIndex !== null ? hoverIndex : selectedIndex;

    const styles = {
        gifPlayer: {
            textAlign: 'center',
            margin: '20px auto',
            padding: '15px 20px',
            backgroundColor: '#ffffff',
            borderRadius: '7px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            width: '100%',
            maxWidth: '1080px',
            fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        },
        title: {
            fontSize: '2rem',
            color: '#000', // 黑色字体
            marginBottom: '8px',
        },
        description: {
            fontSize: '1.1rem',
            lineHeight: '1.5',
            marginBottom: '16px',
            color: '#000', // 与 card-text 相同的颜色
            fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
            fontWeight: '530',
        },
        gifContainer: {
            width: '100%',
            maxWidth: '700px',
            height: 'auto',
            margin: '10px auto',
            overflow: 'hidden',
        },
        img: {
            width: '100%',
            height: 'auto',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
            marginBottom: '15px',
        },
        button: {
            padding: '10px 20px',
        },
        separator: {
            width: '80%',
            height: '1px',
            backgroundColor: '#ddd',
            margin: '10px auto',
        }
    };

    return (
        <div style={styles.gifPlayer}>
            <div style={styles.title}>{titles[currentGifIndex]}</div>
            <div style={styles.description}>{descriptions[currentGifIndex]}</div>
            <div style={styles.separator}></div>
            <div style={styles.buttonContainer}>
                {['ai_detection', 'fine_grained_rewrite', 'long_text_rewrite'].map((label, index) => (
                    <button
                        key={index}
                        className={`btn btn-outline-success btn-lg ${currentGifIndex === index ? 'active' : ''}`}
                        style={styles.button}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => handleMouseClick(index)}
                    >
                        {t(label)}
                    </button>
                ))}
            </div>
            <div style={styles.gifContainer}>
                <img src={gifs[currentGifIndex]} alt={`GIF ${currentGifIndex + 1}`} style={styles.img} />
            </div>
        </div>
    );
};

export default GifPlayer;
