import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'; // 引入 useTranslation

const TextProcessing = ({ defaultService }) => {
  const [service, setService] = useState(defaultService || '');
  const [method, setMethod] = useState('');
  const [text, setText] = useState('');
  const [mode, setMode] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [credits, setCredits] = useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation(); // 使用 useTranslation

  useEffect(() => {
    if (defaultService) {
      setService(defaultService);
    }
  }, [defaultService]);

  const handleServiceChange = (value) => setService(value);
  const handleMethodChange = (value) => setMethod(value);

  const calculateWords = (text) => {
    return text.trim().split(/\s+/).length;
  };

  const handleOpenModal = () => {
    const count = calculateWords(text);
    const creditCost = count * 0.1;
    setWordCount(count);
    setCredits(creditCost);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleConfirm = async () => {
    setShowModal(false);
    setShowLoading(true);

    const userId = sessionStorage.getItem('userId');
    let data = {
      user_id: userId,
      origin_text: text,
    };

    let url;
    if (service === 'Detection') {
      url = `${process.env.REACT_APP_API_BASE_URL}/check`;
    } else if (service === 'Rewrite') {
      url = `${process.env.REACT_APP_API_BASE_URL}/humanize`;
      data.mode = mode;
    }

    const config = {
      method: 'post',
      url: url,
      headers: { 
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(data)
    };

    try {
      const response = await axios(config);
      const result = response.data;

      setShowLoading(false);

      if (result && Object.keys(result).length > 0) {
        if (service === 'Detection') {
          const ai_rate = result.data?.AI || 0;
          const chunks = result.data?.Chunks || [];
          const Label = result.data.Label;
          navigate('/detection_result', { state: { id: userId, original_text: text, ai_rate, chunks, Label: Label } });
        } else if (service === 'Rewrite') {
          const rewritten_text = result.text || t('no_rewrite_text');
          navigate('/rewrite_result', { state: { id: userId, original_text: text, rewritten_text, mode: data.mode } });
        }
      } else {
        alert(t('no_api_data'));
      }
    } catch (error) {
      setShowLoading(false);
      console.error(t('error'), error);

      if (error.response) {
        if (error.response.status === 402 && error.response.data.error === t('insufficient_credits')) {
          alert(t('insufficient_credits_message'));
          navigate('/recharge');
        } else {
          alert(t('request_error'));
        }
      } else {
        alert(t('request_error'));
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!service || !method) {
      alert(t('select_service_method'));
      return;
    }
    if (method === 'text' && text.trim() === '') {
      alert(t('enter_text'));
      return;
    }
    if (service === 'Rewrite' && !mode) {
      alert(t('select_rewrite_mode'));
      return;
    }

    handleOpenModal();
  };

  return (
    <div className="container">
      <div className="text-center mb-4">
        <h1 className="text-success">{t('aigc_detection')}</h1>
      </div>
      <div className="alert alert-warning" role="alert">
        {t('english_only_warning')}
      </div>
      <h2 className="h4">{t('aigc_usage_detection')}</h2>
      <div className="p-3 mb-3 bg-light rounded">
        <h3 className="h5">{t('service_settings')}</h3>
        <form id="uploadForm" onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className="form-label">{t('service')}：</label>
            <div>
              <button type="button" className={`btn ${service === 'Detection' ? 'btn-success' : 'btn-outline-success'} me-2`} onClick={() => handleServiceChange('Detection')}>{t('aigc_detection')}</button>
              <button type="button" className={`btn ${service === 'Rewrite' ? 'btn-success' : 'btn-outline-secondary'}`} onClick={() => handleServiceChange('Rewrite')}>{t('rewrite')}</button>
            </div>
          </div>
          <div className="mb-3">
            <label className="form-label">{t('method')}：</label>
            <div>
              <button type="button" className={`btn ${method === 'upload' ? 'btn-success' : 'btn-outline-success'} me-2`} onClick={() => handleMethodChange('upload')}>{t('upload_file')}</button>
              <button type="button" className={`btn ${method === 'text' ? 'btn-success' : 'btn-outline-secondary'}`} onClick={() => handleMethodChange('text')}>{t('enter_text')}</button>
            </div>
          </div>
          {method === 'upload' && (
            <div className="upload-area">
              <input type="file" className="form-control" />
              <p className="small text-muted mt-2">{t('file_upload_info')}</p>
            </div>
          )}
          {method === 'text' && (
            <div className="text-area">
              <textarea className="form-control" rows="10" placeholder={t('enter_text_placeholder')} value={text} onChange={(e) => setText(e.target.value)}></textarea>
            </div>
          )}
          {service === 'Rewrite' && (
            <div className="mb-3">
              <label className="form-label">{t('rewrite_mode')}：</label>
              <select className="form-select" value={mode} onChange={(e) => setMode(e.target.value)}>
                <option value="">{t('select_mode')}</option>
                <option value="easy">{t('easy')}</option>
                <option value="medium">{t('medium')}</option>
                <option value="aggressive">{t('aggressive')}</option>
              </select>
            </div>
          )}
          <button type="submit" className="btn btn-success mt-3">{t('detect')}</button>
        </form>
      </div>
      <p className="text-muted">{t('credits_usage_info')}</p>

      {/* Loading Modal */}
      <Modal show={showLoading} centered>
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">{t('loading')}</span>
          </Spinner>
          <p className="mt-3">{t('processing_request')}</p>
        </Modal.Body>
      </Modal>

      {/* Confirmation Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('confirm_credits_usage')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('credits_confirmation', { credits: credits.toFixed(1), wordCount })}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t('cancel')}
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            {t('confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TextProcessing;
