import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import { useTranslation } from 'react-i18next'; // 引入 useTranslation

const RewriteResult = () => {
  const location = useLocation();
  const { t } = useTranslation(); // 使用 useTranslation
  const { id, original_text, rewritten_text, mode } = location.state || { id: '', original_text: '', rewritten_text: t('no_rewritten_text'), mode: 'N/A' };

  useEffect(() => {
    try {
    } catch (error) {
      console.error(t('error'), error);
      console.log(t('id'), id);
    }
  }, [id, t]);

  const formatTextForAnimation = (text) => {
    return text.split('\n').map((line) => line.trim()).join('\n');
  };

  const formattedRewrittenText = formatTextForAnimation(rewritten_text);

  return (
    <div className="container mt-5">
      <h1 className="text-center text-success">{t('rewrite_result')}</h1>
      <div className="row">
        <div className="col-md-6">
          <div className="card mb-4 shadow-sm">
            <div className="card-body">
              <h3 className="card-title">{t('original_text')}</h3>
              <div style={{ whiteSpace: 'pre-wrap' }}>{original_text}</div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card mb-4 shadow-sm">
            <div className="card-body">
              <h3 className="card-title">{t('rewritten_text')}</h3>
              <div style={{ whiteSpace: 'pre-wrap' }}>
                <TypeAnimation
                  sequence={[formattedRewrittenText]}
                  wrapper="span"
                  speed={80}
                  style={{ fontSize: '1rem' }}
                  repeat={0}
                />
              </div>
              <p><strong>{t('rewrite_mode')}:</strong> {mode}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mt-4">
        <a href="/AI_detector" className="btn btn-primary">{t('back')}</a>
      </div>
    </div>
  );
};

export default RewriteResult;
