import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Spinner, Modal, Container, Row, Col, Badge, Button, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'; // 引入 useTranslation

const CreditHistory = () => {
  const [historyType, setHistoryType] = useState('spend_history');
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const userId = sessionStorage.getItem('userId');
  const navigate = useNavigate();
  const { t } = useTranslation(); // 使用 useTranslation

  useEffect(() => {
    if (!userId) {
      setShowLoginModal(true);
    } else {
      fetchHistoryData(historyType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyType, userId]);

  const fetchHistoryData = async (type) => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/history`, {
        user_id: userId,
        history_type: type
      });
      setHistoryData(response.data.sort((a, b) => new Date(b.time) - new Date(a.time)));
    } catch (error) {
      console.error(t('fetch_error'), error); // 替换硬编码的错误提示
    }
    setLoading(false);
  };

  const handleLoginRedirect = () => {
    setShowLoginModal(false);
    navigate('/login');
  };

  const handleHomeRedirect = () => {
    setShowLoginModal(false);
    navigate('/');
  };

  const renderHistory = () => {
    return historyData.map((item, index) => {
      if (historyType === 'spend_history') {
        return (
          <Card key={`spend-${item.spend_id || index}`} className="mb-3">
            <Card.Body>
              <Container fluid>
                <Row>
                  <Col><Badge bg="primary" className="px-2 py-1">{t('spend_id')}:</Badge> {item.spend_id}</Col>
                  <Col><Badge bg="info" className="px-2 py-1">{t('type')}:</Badge> {item.spend_type}</Col>
                  <Col><Badge bg="success" className="px-2 py-1">{t('time')}:</Badge> {new Date(item.time).toLocaleString('zh-CN', { hour12: false })}</Col>
                  <Col><Badge bg="danger" className="px-2 py-1">{t('spend')}:</Badge> {item.spend_credit} {t('points')}</Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        );
      } else if (historyType === 'recharge_history') {
        return (
          <Card key={`recharge-${item.recharge_id || index}`} className="mb-3">
            <Card.Body>
              <Container fluid>
                <Row>
                  <Col><Badge bg="primary" className="px-2 py-1">{t('recharge_id')}:</Badge> {item.recharge_id}</Col>
                  <Col><Badge bg="info" className="px-2 py-1">{t('type')}:</Badge> {item.recharge_type}</Col>
                  <Col><Badge bg="success" className="px-2 py-1">{t('time')}:</Badge> {new Date(item.time).toLocaleString('zh-CN', { hour12: false })}</Col>
                  <Col><Badge bg="danger" className="px-2 py-1">{t('amount')}:</Badge> {item.amount} {item.amount_type}</Col>
                  <Col><Badge bg="warning" className="px-2 py-1">{t('recharge_credit')}:</Badge> {item.recharge_credit} {t('points')}</Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        );
      }
      return null;
    });
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4" style={{ fontFamily: 'Arial, sans-serif', fontSize: '2rem' }}>{t('credit_history')}</h1>
      <div className="btn-group mb-4" role="group" aria-label={t('history_type')}>
        <Button
          variant={historyType === 'spend_history' ? 'primary' : 'outline-primary'}
          onClick={() => setHistoryType('spend_history')}
          style={{ fontFamily: 'Arial, sans-serif', fontSize: '1rem' }}
        >
          {t('spend_history')}
        </Button>
        <Button
          variant={historyType === 'recharge_history' ? 'primary' : 'outline-primary'}
          onClick={() => setHistoryType('recharge_history')}
          style={{ fontFamily: 'Arial, sans-serif', fontSize: '1rem' }}
        >
          {t('recharge_history')}
        </Button>
      </div>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">{t('loading')}</span>
          </Spinner>
        </div>
      ) : (
        <div>
          {renderHistory()}
        </div>
      )}
      <Modal show={showLoginModal} onHide={handleHomeRedirect} centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontFamily: 'Arial, sans-serif', fontSize: '1.5rem' }}>{t('login_required')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontFamily: 'Arial, sans-serif', fontSize: '1rem' }}>
          <p>{t('login_prompt')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHomeRedirect}>
            {t('no_home')}
          </Button>
          <Button variant="primary" onClick={handleLoginRedirect}>
            {t('yes_login')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreditHistory;
