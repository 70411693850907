import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Button, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import PaymentModal from '../components/PaymentModal';
import './css/Recharge.css';
import { useTranslation } from 'react-i18next'; // 引入 useTranslation

const basePrices = {
  basic: 99,
  standard: 199,
  premium: 399,
  diamond: 799,
  enterprise: 1599,
};

const Recharge = () => {
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailedModal, setShowFailedModal] = useState(false);
  const [paymentMessage, setPaymentMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const customerId = sessionStorage.getItem('userId');
  const { t } = useTranslation(); // 使用 useTranslation
  const packages = [
    { id: 1, name: t('basic_package'), price: `￥${(basePrices.basic).toFixed(2)}`, credits: '2,000', stripePrice: basePrices.basic },
    { id: 2, name: t('standard_package'), price: `￥${(basePrices.standard).toFixed(2)}`, credits: '4,200', stripePrice: basePrices.standard },
    { id: 3, name: t('premium_package'), price: `￥${(basePrices.premium).toFixed(2)}`, credits: '11,000', stripePrice: basePrices.premium },
    { id: 4, name: t('diamond_package'), price: `￥${(basePrices.diamond).toFixed(2)}`, credits: '23,000', stripePrice: basePrices.diamond },
    { id: 5, name: t('enterprise_package'), price: `￥${(basePrices.enterprise).toFixed(2)}`, credits: '120,000', stripePrice: basePrices.enterprise },
  ];

  useEffect(() => {
    if (!customerId) {
      setShowLoginModal(true);
    }
  }, [customerId]);

  const handlePackageSelect = (pkg) => {
    setSelectedPackage(pkg);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleLoginRedirect = () => {
    setShowLoginModal(false);
    navigate('/login');
  };

  const handleHomeRedirect = () => {
    setShowLoginModal(false);
    navigate('/');
  };

  const handleSuccessClose = () => setShowSuccessModal(false);
  const handleFailedClose = () => setShowFailedModal(false);

  return (
    <div className="container recharge-container bg-white p-4 rounded shadow" style={{ marginTop: '60px' }}>
      <h1 className="text-center">{t('recharge')}</h1>
      <p className="text-center">{t('recharge_description')}</p>
      <div className="payment-icons text-center my-3">
        <i className="bi bi-credit-card" title={t('credit_card')}></i>
        <i className="bi bi-paypal" title={t('paypal')}></i>
        <i className="bi bi-apple" title={t('apple_pay')}></i>
        <i className="bi bi-google" title={t('google_pay')}></i>
        <i className="bi bi-wallet2" title={t('alipay')}></i>
        <i className="bi bi-wechat" title={t('wechat_pay')}></i>
      </div>
      <Row className="justify-content-center">
        {packages.map((pkg) => (
          <Col key={pkg.id} xs={12} sm={6} md={4} className="mb-4">
            <Card className="package-card">
              <Card.Body>
                <Card.Title>{pkg.name}</Card.Title>
                <Card.Text className="price">{pkg.price}</Card.Text>
                <Card.Text className="credits">{pkg.credits} NLPhraser {t('credits')}</Card.Text>
                <Button variant="success" onClick={() => handlePackageSelect(pkg)}>{t('select_package')}</Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      {selectedPackage && (
        <PaymentModal
          show={showModal}
          handleClose={handleCloseModal}
          selectedPackage={selectedPackage}
        />
      )}
      <Modal show={showLoginModal} onHide={handleHomeRedirect}>
        <Modal.Header closeButton>
          <Modal.Title>{t('not_logged_in')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontFamily: 'Arial, sans-serif', fontSize: '1rem' }}>
          <p>{t('login_prompt_recharge')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHomeRedirect}>
            {t('back_home')}
          </Button>
          <Button variant="primary" onClick={handleLoginRedirect}>
            {t('login')}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showSuccessModal} onHide={handleSuccessClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('payment_success')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{paymentMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleSuccessClose}>
            {t('close')}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showFailedModal} onHide={handleFailedClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('payment_failed')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{paymentMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleFailedClose}>
            {t('close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Recharge;
