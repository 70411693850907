// components/static/Testimonials.js
import React from 'react';

const testimonials = [
  {
    name: 'Emily Johnson',
    title: 'Freelance Writer',
    text: 'NLPhraser has significantly improved the quality of my writing by reducing the AI-generated content. The detection tool is fast and reliable!',
    img: 'https://randomuser.me/api/portraits/women/22.jpg'
  },
  {
    name: 'Michael Smith',
    title: 'SEO Specialist',
    text: 'Thanks to NLPhraser, I can now ensure that all content is human-generated, which is crucial for my clients.',
    img: 'https://randomuser.me/api/portraits/men/33.jpg'
  },
  {
    name: 'Sarah Williams',
    title: 'Graduate Student',
    text: 'NLPhraser has been a lifesaver in avoiding AI-generated content. I highly recommend it to other students!',
    img: 'https://randomuser.me/api/portraits/women/34.jpg'
  },
  {
    name: 'David Brown',
    title: 'Tech Blogger',
    text: 'I use NLPhraser daily to ensure that my articles are 100% human-written. The accuracy is truly impressive!',
    img: 'https://randomuser.me/api/portraits/men/45.jpg'
  },
  {
    name: 'Jessica Davis',
    title: 'Data Analyst',
    text: 'The AI detection feature of NLPhraser is outstanding. It gives me peace of mind knowing my reports are original.',
    img: 'https://randomuser.me/api/portraits/women/50.jpg'
  },
  {
    name: 'John Miller',
    title: 'Novelist',
    text: 'NLPhraser’s tools have simplified my writing process, ensuring originality in all my creative projects.',
    img: 'https://randomuser.me/api/portraits/men/51.jpg'
  }
];


const Testimonials = () => {
  return (
    <div className="container mt-5">
      <h2 className="text-center">User Reviews</h2>
      <div className="row">
        {testimonials.map((testimonial, index) => (
          <div className="col-md-4 mb-4" key={index}>
            <div className="card h-100 text-center">
              <img src={testimonial.img} className="card-img-top" alt={testimonial.name} style={{ borderRadius: '50%', width: '100px', height: '100px', margin: '20px auto 10px auto' }} />
              <div className="card-body">
                <h5 className="card-title">{testimonial.name}</h5>
                <h6 className="card-subtitle mb-2 text-muted">{testimonial.title}</h6>
                <p className="card-text">{testimonial.text}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
